// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../mixins/mixins';
@import '../../variables/colors';

// -----------------------------------------------------------------------------------------------------
// @ Mat Slide Toggle
// -----------------------------------------------------------------------------------------------------

.mat-slide-toggle-content{
  @include text-inter-medium;
}

.mat-slide-toggle-thumb {
  width: 0.9375rem !important;
  height: 0.9375rem !important;
  transform: translate(10%, 15%);
  box-shadow: 0 0 0 0.0625rem rgb(0, 0, 0, 0.10);
}

.mat-slide-toggle-bar {
  background-color: $gray-200;
  border-radius: 0.9375rem !important;
  height: 1.25rem !important;
  width: 2.3125rem !important;
}

.mat-slide-toggle-thumb-container {
  top: 0 !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  transform: translate(10%, 8%);
  background-color: var(--primary-color-medium);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: $white;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  border: 0.0625rem solid var(--primary-color-medium);
  background-color: $white;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  transform: translate(10%, 10%);
  background-color: var(--primary-color-medium);
}
