
@mixin dialog-shadow {
  box-shadow: 0rem 0.125rem 1.0625rem rgba(0, 0, 0, 0.167395);
}

@mixin clicked-button-shadow {
  box-shadow: 0rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.08);
}

@mixin button-hover-shadow {
  box-shadow: 0rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.20);
  // box-shadow: 0rem 0.3125rem 0.5rem rgba(0, 0, 0, 0.5);
}

@mixin button-focus-shadow {
  box-shadow: 0rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.1);
}

@mixin sub-menu-shadow {
  box-shadow: -0.125rem 0rem 0.5rem rgba(0, 0, 0, 0.12);
}

@mixin chat-field-shadow {
  box-shadow: 0rem -0.25rem 0.75rem rgba(0, 0, 0, 0.08);
}

@mixin cards-shadow {
  box-shadow: 0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.12);
}
