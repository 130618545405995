// -----------------------------------------------------------------------------------------------------
// @ Default Values
// -----------------------------------------------------------------------------------------------------
@function toRem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
}

// Espaçamentos.
$gap-xsmall-container: toRem(4);
$gap-small-container: toRem(8);
$gap-default-container: toRem(16);
$gap-xdefault-container: toRem(24);
$gap-large-container: toRem(48);
$gap-xlarge-container: toRem(96);

$padding-larger-container: 1.25rem;
$padding-default-container: 1rem;

$border-radius-default: 0.25rem;
$border-radius-large: 0.5rem;


// Grids
@mixin grid-default {
    max-width: 70rem;
}

@mixin grid-sm {
    @media screen and (max-width: 820px) {
        display: flex;
        justify-content: center;
        max-width: none;
        width: 100%;
  }
}

@mixin grid-lg {
    @media screen and (min-width: 1600px) {
        width: 14rem;
        max-width: 14rem;
    }
}

@mixin grid-xl {
    @media screen and (min-width: 1750px) {
        display: flex;
        flex-wrap: no-wrap;
        width: 70rem;
        max-width: 70rem;
  }
}

$box-shadow-default: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.26);

@mixin webkit-scroll {
    ::-webkit-scrollbar {
        width: 0.125rem;
        height: 0.5rem;
      }

    ::-webkit-scrollbar-track {
        border-radius: 1.25rem;
        background-color: #EBF5FF;
      }

    ::-webkit-scrollbar-thumb {
        background: $blue-600;
      }

    ::-webkit-scrollbar-thumb:hover {
        background: $blue-400;
      }
}

$toastr-image-path: "../../../../assets/icons/logos/notification.svg";
