// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../variables/colors';
@import '../../mixins/mixins.scss';

// -----------------------------------------------------------------------------------------------------
// @ Mat Select
// -----------------------------------------------------------------------------------------------------
.mat-form-field-infix {
  height: 2.5rem;
  color: var(--gray-500);
  border-top: 0;
  padding: 0 0;
  .mat-select {
    @include text-font-inter;
    font-size: 0.875rem !important;
    line-height: 2.1875rem;
    padding: 0 0 0 0.625rem;
    position: relative;
    top: 0.125rem;
  }
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  padding: 0.5rem 0.625rem 0 0;
}

//hover
.mat-form-field-appearance-outline:hover .mat-select-arrow-wrapper .mat-select-arrow {
  background-color: var(--primary-color-medium) !important;
}

//Focus
.mat-form-field-appearance-outline.mat-focused .mat-select-arrow-wrapper .mat-select-arrow {
  background-color: var(--primary-color-medium) !important;
}

//Icone disabled some
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-select-arrow-wrapper .mat-select-arrow {
  display: none !important;
}

// Altera o tipo da seta
.mat-select-arrow {
  width: 0.625rem !important;
  height: 0.3125rem !important;
  border: 0 !important;
  margin: 0 !important;
  background-color: var(--gray-300) !important;
  clip-path: polygon(50% 68%, 10% 0%, 0% 12%, 50% 100%, 100% 12%, 90% 0%) !important;
}

.mat-select-panel .mat-option {
  @include text-inter-h4;
}


.mat-option.mat-active{
  // color: black !important;
  // background-color: var(--gray-100) !important;
  // border: 0.125rem solid var(--primary-color-dark) !important;
  .mat-pseudo-checkbox {
    // color: var(--primary-color-dark) !important;
    // background-color: var(--white);
    // box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  }
}

.mat-option.mat-active.mat-selected{
  color: var(--white) !important;
  background-color: var(--primary-color-medium) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--white);
  background-color: var(--primary-color-medium);
}

.mat-option:hover:not(.mat-option-disabled){
  // font-weight: bold;
  background-color: var(--primary-color-light) !important;
  color: var(--black) !important;
}

.mat-option.mat-selected:hover:not(.mat-option-disabled){
  // font-weight: bold;
  color: var(--black);
}


// -----------------------------------------------------------------------------------------------------
// @ Ngx Mat Select Search
// -----------------------------------------------------------------------------------------------------

.filter-panel {
  max-width: 7.5rem !important;
}

.filter-container .mat-select-arrow {
  display: none;
}

ngx-mat-select-search {
  .mat-select-search-inner.mat-select-search-inner-multiple {
    height: 2.6875rem !important;
  }

  .mat-select-search-no-entries-found {
    padding: 1rem 0.75rem !important;
    color: var(--gray-500);
  }

  .mat-select-search-clear {
    top: 0 !important;
    right: 0.25rem !important;
  }

  .mat-icon-button {
    width: 2.5rem !important;
    height: 2.5rem !important;
    line-height: 2.5rem !important;
}
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  font-size: 0.875rem !important;
  line-height: 2em !important;
  height: 2.625rem !important;
}
