// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

//...

// -----------------------------------------------------------------------------------------------------
// @ Mat Icons
// -----------------------------------------------------------------------------------------------------

.mat-icon {
  font-size: 1.5rem;
  height: 1.5rem !important;
  width: 1.5rem !important;
  line-height: 1.5rem !important;
}
