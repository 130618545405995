.mat-tab-label {
  color: #333333;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  min-width: 3.75rem !important;
  height: 2.25rem !important;
  width: 11.5rem !important;
}

.mat-tab-label.mat-tab-label-active {
  color: var(--primary-color-medium) !important;
  opacity: 1 !important;
}
