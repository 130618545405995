// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

// ...

// -----------------------------------------------------------------------------------------------------
// @ Mat Menu
// -----------------------------------------------------------------------------------------------------

.mat-menu-content:not(:empty) {
  padding: 0.375rem 0 0.375rem 0 !important;
  max-height: 12.5rem;
  max-width: 13.25rem;
  @media (max-width: 600px) {
    max-width: 11.5rem;
    button.mat-menu-item {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
  }
  button.mat-menu-item {
    width: 100%;
    height: 2.75rem;
    line-height: 1;
    padding: 0 1rem 0 0.875rem
  }
  .mat-menu-item .mat-icon {
    margin-right: 0.625rem;
    vertical-align: middle;
  }
}
