// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------


// Para inserir tooltip no mat-label crie uma classe
// e adicione pointer-events: auto; na mesma
@import "../../mixins/mixins";
@import "../../mixins/shadow-mixins.scss";
@import "../../variables/variables";

// -----------------------------------------------------------------------------------------------------
// @ Mat Form Field
// -----------------------------------------------------------------------------------------------------

.mat-form-field {
  &, * {transition: all .3s linear;}
}

// Border
// Default Color
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $gray-200;
}

.mat-form-field-infix {
  // background-color: yellow;
  margin: -0.25rem 0 0.25rem 0;
  height: 2.5rem;
  color: $gray-400;
  border-top: 0;
  padding: 0 0;
}

.mat-form-field ::placeholder {
  @include text-font-inter;
  font-size: 0.875rem !important;
}

//cor do placeholder
// .mat-form-field:hover ::placeholder {
//   color: #333333;
// }

input.mat-input-element {
  @include text-font-inter;
  font-size: 0.875rem !important;
  color: #333333;
  padding: 0 0.625rem;
  font-style: normal;
  font-weight: 400;

  &[type=time] {
    width: 95%;
    position: relative;
    top: -0.125rem;
  }

  &[type="time"]::-webkit-calendar-picker-indicator {
    font-size: 1rem !important;
    // filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
  }
}

textarea.mat-input-element {
  @include text-font-inter;
  font-size: 0.875rem !important;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  padding: 0 0.625rem !important;
  resize: none !important;
  margin: 0 0 0.625rem 0 !important;
}

//Padding para o input e label
.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 0.1em 0 0.1em !important;
}

// Label
.mat-form-field-label {
  @include text-font-inter;
  font-size: 1.1625rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  width: 100%;

  .inputHeader {
    display: flex;
    text-align: end;
    justify-content: space-between;

    .firstLabel {
      color: #333333;
    }

    .optionalLabel,
    .inputLength {
      color: #595959;
    }
  }
}

//Hover mat-form-field
.mat-form-field-flex {
  &.focus-open-select {
    .mat-form-field-outline {
      .mat-form-field-outline-gap, .mat-form-field-outline-end, .mat-form-field-outline-start {
        border: 0.125rem solid currentColor !important;
      }
      .mat-form-field-outline-gap, .mat-form-field-outline-start {
        border-right-style: none !important;
      }
      .mat-form-field-outline-gap, .mat-form-field-outline-end {
        border-left-style: none !important;
      }
      color: var(--primary-color-medium) !important;
      border-width: 0.0625rem !important;
    }
  }
  &:hover {
    @include button-hover-shadow;

    .mat-form-field-outline {
      color: var(--primary-color-medium) !important;
      background-color: var(--primary-color-light);
      border-width: 0.0625rem !important;
    }
    .select-infinite-scroll-search {
      .mat-select-value {
        color: var(--gray-500) !important;
      }
      .mat-select-placeholder {
        color: var(--gray-300) !important;
      }
    }
  }
}



//Borda com 1px
.mat-form-field-appearance-outline:hover .mat-form-field-outline-start,
.mat-form-field-appearance-outline:hover .mat-form-field-outline-end,
.mat-form-field-appearance-outline:hover .mat-form-field-outline-gap {
  border-width: 0.0625rem !important;
}

//Focus mat-form-field
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--primary-color-medium) !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-suffix {
  .mat-icon {
    color: var(--primary-color-medium) !important;
    svg {
      path {
        // fill: var(--primary-color-medium) !important;
      }
    }
  }
}

//Disabled label
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: $gray-500 !important;
}

//Icone disabled some
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-suffix {
  display: none !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-flex:hover {
  color: $gray-500 !important;
  box-shadow: none !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: inherit !important;
}

//icon select
// .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
//   // transform: translateY(0) !important;
//   margin: 0.9375rem 0 0 0;
// }

// Border Disabled
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: $gray-100;
  border-color: $gray-300 !important;
  box-shadow: none !important;

  div {
    border-color: $gray-300 !important;
  }
}

// Label Position alterar o label
.mat-form-field-label-wrapper {
  @include text-font-inter;
  // left: -0.5625rem !important;
  width: 100%;
}

.mat-input-element {
  @include text-font-inter;
  font-size: 0.875rem;
  width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 1em 0 0.5em 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1rem 0 1rem 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding-left: 0.0625rem !important;
}

// dark
mat-form-field.dark {
  .mat-form-field-outline {
    div {
      background-color: $white;
    }
  }

  mat-label {
    color: $white;
  }

  mat-error {
    color: $red-300;
    filter: drop-shadow(0.0625rem 0.0625rem 0.0625rem rgba(1, 1, 1, 0.5));
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Icon Font Awesome Icons
// -----------------------------------------------------------------------------------------------------

.mat-form-field {
  @include text-font-inter;
  font-size: 0.875rem;
  width: 100%;

  .mat-icon.fa {
    position: relative;
    height: 1.25rem !important;
    width: 1.25rem !important;
    font-size: 0.875rem;

    &:before {
      position: absolute;
      top: 0.1875rem;
    }
  }
  input{
    width: 95%;
    height: 150%;
    display: flex;
    justify-content: center;
    margin-top: 0.125rem;
  }
  textarea{
    width: 95%;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Validation Status
// -----------------------------------------------------------------------------------------------------

.warning.mat-form-field {
  &.mat-focused {
    .mat-form-field-label {
      color: $gray-500;
    }
  }

  .mat-form-field-outline {
    color: $yellow-500;
    border-width: 0.125rem;

    .mat-form-field-outline-start {
      border-width: 0.125rem;
    }

    .mat-form-field-outline-gap {
      border-width: 0.125rem;
    }

    .mat-form-field-outline-end {
      border-width: 0.125rem;
    }
  }

  .mat-icon {
    color: $yellow-500;
  }

  mat-hint {
    color: $yellow-500;
  }
}

.confirm.mat-form-field {
  &.mat-focused {
    .mat-form-field-label {
      color: $gray-500;
    }
  }

  .mat-form-field-outline {
    color: $green-400;
    border-width: 0.125rem;

    .mat-form-field-outline-start {
      border-width: 0.125rem;
    }

    .mat-form-field-outline-gap {
      border-width: 0.125rem;
    }

    .mat-form-field-outline-end {
      border-width: 0.125rem;
    }
  }

  .mat-icon {
    color: $green-400;
  }

  mat-hint {
    color: $green-400;
  }
}

.error.mat-form-field {
  &.mat-focused {
    .mat-form-field-label {
      color: $gray-500;
    }
  }

  .mat-form-field-outline {
    color: $red-500;
    border-width: 0.125rem;

    .mat-form-field-outline-start {
      border-width: 0.125rem;
    }

    .mat-form-field-outline-gap {
      border-width: 0.125rem;
    }

    .mat-form-field-outline-end {
      border-width: 0.125rem;
    }
  }

  .mat-icon {
    color: $red-500;
  }

  mat-hint {
    color: $red-500;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Error
// -----------------------------------------------------------------------------------------------------

.mat-error {
  font-size: 0.625rem;
  margin-top: -0.0625rem;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  height: 2rem !important;
  width: 2rem !important;
}

//Icone antes do valor
.mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0.0625rem !important;
  left: 0.5rem;
}

//Icone depois do valor
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.3em !important;
}

//Icone calendario depois do valor
.mat-form-field-appearance-outline .mat-form-field-suffix .mat-datepicker-toggle {
  position: relative;
  top: -0.25rem;

  .mat-icon {
    svg {
      path {
        fill: #595959 !important;
      }
    }
  }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-suffix .mat-datepicker-toggle {
  .mat-icon {
    svg {
      path {
        fill: #595959 !important;
      }
    }
  }
}
// .mat-select-value {
//   position: relative;
//   top: 0.375rem;
// }
