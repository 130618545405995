// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

/*
 * Post that can help you change and manipulate the theme
 * https://material.angular.io/guide/typography
 * https://stackoverflow.com/questions/43438305/how-to-change-font-color-of-primary-palette-in-angular-material2/46157803#46157803
 */

@import '~@angular/material/theming';

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

/* Example example to change typography */
//$typography: mat-typography-config(
//  $font-family: 'Proxima Nova Alt Bold, sans-serif',
//  $display-4:     mat-typography-level(7rem, 7rem, 300, $letter-spacing: -0.05em),
//  $display-3:     mat-typography-level(3.5rem, 3.5rem, 400, $letter-spacing: -0.02em),
//  $display-2:     mat-typography-level(2.8125rem, 3rem, 400, $letter-spacing: -0.005em),
//  $display-1:     mat-typography-level(2.125rem, 2.5rem, 400),
//  $headline:      mat-typography-level(1.5rem, 2rem, 400),
//  $title:         mat-typography-level(1.25rem, 2rem, 500),
//  $subheading-2:  mat-typography-level(1rem, 1.75rem, 400),
//  $subheading-1:  mat-typography-level(0.9375rem, 1.5rem, 400),
//  $body-2:        mat-typography-level(0.875rem, 1.5rem, 500),
//  $body-1:        mat-typography-level(0.875rem, 1.25rem, 400),
//  $caption:       mat-typography-level(0.75rem, 1.25rem, 400),
//  $button:        mat-typography-level(0.875rem, 0.875rem, 500),
//    // Line-height must be unit-less fraction of the font-size.
//  $input:         mat-typography-level(inherit, 1.125, 400)
//);
//
//@include mat-core($typography);

// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

// Import of the whole core content of the Material Theme
// It's important to remember, however, that the mat-core mixin should only ever be included once.
@include mat-core();

// -----------------------------------------------------------------------------------------------------
// @ Light Theme
// -----------------------------------------------------------------------------------------------------

$light-app-primary: mat-palette($mat-blue, 500, 300, 700);
$light-app-accent: mat-palette($mat-green, 600, 300, 700);
$light-app-warn: mat-palette($mat-red, A200);

$light-app-theme: mat-light-theme($light-app-primary, $light-app-accent, $light-app-warn);

$light-app-font-color: mat-palette($mat-grey, 700); /* #616161 */

// Foreground palette for light themes.
// You can play with the map and define only the fields you want and leave the others as default.
@function main-mat-light-theme-foreground($color) {
  @return (
      base:              $color,
      divider:           $black-12-opacity,
      dividers:          $black-12-opacity,
      disabled:          rgba($color, 0.38),
      disabled-button:   rgba($color, 0.26),
      disabled-text:     rgba($color, 0.38),
      elevation:         black,
      hint-text:         rgba($color, 0.36),
      secondary-text:    rgba($color, 1.00), /* Secondary Text */
      icon:              rgba($color, 1.00), /* Icon Color */
      icons:             rgba($color, 1.00), /* Icons Color */
      text:              rgba($color, 1.00), /* Text Color */
      slider-min:        rgba($color, 0.87),
      slider-off:        rgba($color, 0.26),
      slider-off-active: rgba($color, 0.38),
  );
}
// Variable that has the entire foreground palette
$light-foreground-palette: main-mat-light-theme-foreground(mat-color($light-app-font-color));

// Merge app-theme with the foreground
$light-app-main-theme: map-merge($light-app-theme, (foreground: $light-foreground-palette));

// Include your custom theme.
@include angular-material-theme($light-app-main-theme);

// -----------------------------------------------------------------------------------------------------
// @ Dark Theme
// -----------------------------------------------------------------------------------------------------

$dark-app-primary: mat-palette($mat-blue, 500, 300, 700);
$dark-app-accent: mat-palette($mat-green, 600, 300, 700);
$dark-app-warn: mat-palette($mat-red, A200);

$dark-app-theme: mat-dark-theme($dark-app-primary, $dark-app-accent, $dark-app-warn);

$dark-app-font-color: mat-palette($mat-grey, A100); /* #FFFFFF */

@function main-mat-dark-theme-foreground($color) {
  @return (
    base:              $color,
    divider:           $black-12-opacity,
    dividers:          $black-12-opacity,
    disabled:          rgba($color, 0.38),
    disabled-button:   rgba($color, 0.26),
    disabled-text:     rgba($color, 0.38),
    elevation:         black,
    hint-text:         rgba($color, 0.36),
    secondary-text:    rgba($color, 1.00), /* Secondary Text */
    icon:              rgba($color, 1.00), /* Icon Color */
    icons:             rgba($color, 1.00), /* Icons Color */
    text:              rgba($color, 1.00), /* Text Color */
    slider-min:        rgba($color, 0.87),
    slider-off:        rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
  );
}

$dark-foreground-palette: main-mat-dark-theme-foreground(mat-color($dark-app-font-color));

$dark-app-main-theme: map-merge($dark-app-theme, (foreground: $dark-foreground-palette));

// Apply the class to change to dark mode
.dark-mode{
  @include angular-material-theme($dark-app-main-theme);
}

.mat-tooltip {
  font-size: 0.875rem;
}

.mat-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-button.cdk-program-focused .mat-button-focus-overlay, .mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-icon-button.cdk-program-focused .mat-button-focus-overlay, .mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-stroked-button.cdk-program-focused .mat-button-focus-overlay, .mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-flat-button.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Override
// -----------------------------------------------------------------------------------------------------

@import './../themes/override/mat-icons';
@import './../themes/override/mat-menu';
@import './../themes/override/mat-expansion-panel';
@import './../themes/override/mat-button.scss';
@import './../themes/override/mat-select.scss';
@import './../themes/override/mat-form-field.scss';
@import './../themes/override/mat-checkbox';
@import './../themes/override/mat-dialog';
@import './../themes/override/mat-form-field';
@import './../themes/override/mat-slider-toggle.scss';
@import './../themes/override/mat-radio.scss';
@import './../themes/override/mat-table.scss';
@import './../themes/override/mat-tab-group.scss';
@import './../themes/override/mat-tooltip.scss';
