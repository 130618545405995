// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import "../../mixins/mixins";
@import "../../variables/variables";
@import "../../../../src/styles/variables/colors";

// Legacy variables
$primary: $blue-500;
$accent: $orange-500;
$warn: $red-500;
$dividers: $gray-100;
$gray-800: #6d6f72;
$gray-650: #6d6d6d;

// -----------------------------------------------------------------------------------------------------
// @ Mat Table
// -----------------------------------------------------------------------------------------------------

table.mat-table {
  input {
    @include text-inter-medium;
  }

  margin-bottom: 0.25rem;
  background-color: transparent;
  padding: 0 0.125rem;

  thead,
  tfoot {
    tr {

      th,
      td {
        white-space: nowrap;
        border: none;
        padding: 0 0.9375rem;
        text-align: left;
        background: $white;

        .mat-sort-header-button {
          position: relative;

          &:before {
            @include text-font-inter;
            font-weight: 500;
            color: $gray-400;
            height: 1rem;
            font-size: 0.75rem;
            position: absolute;
            top: 0;
            left: -0.9375rem;
            opacity: 0;
            transition: opacity 0.2s ease-out;
          }
        }

        &:hover {
          .mat-sort-header-button {
            &:before {
              opacity: 0.6;
              content: "\f15e";
            }
          }
        }
      }

      td button {
        text-align: center;
      }

      td.header-total {
        :first-of-type {
          text-align: right !important;
          font-size: 0.875rem !important;
          font-weight: 500 !important;
        }

        font-size: 1rem !important;
        font-weight: 700 !important;
        text-align: left;
        color: $gray-800 !important;
      }

      th.mat-header-cell,
      td.mat-header-cell {
        @include text-inter-small;
        color: $gray-400;

        .mat-sort-header-position-before {
          justify-content: center;
        }

        .mat-sort-header-stem,
        .mat-sort-header-pointer-left,
        .mat-sort-header-pointer-right,
        .mat-sort-header-pointer-middle {
          display: none;
        }
      }

      th.mat-sort-header-disabled,
      td.mat-sort-header-disabled {
        &.mat-header-cell:first-of-type {
          padding-left: 2.25rem;
        }

        .mat-sort-header-button {
          &:before {
            opacity: 0;
          }
        }
      }

      th.mat-header-cell:first-of-type,
      td.mat-header-cell:first-of-type {
        padding-right: 0.4375rem;
      }

      th.header-actions-container,
      td.header-actions-container {
        .title-text {
          white-space: nowrap;
        }

        .sort-container,
        .filter-container,
        .pin-container,
        .lock-container {
          // Ajusta o tamanho do container dos botoes
          display: inline-flex;
          width: 1.25rem;
          height: 1.25rem;
          vertical-align: bottom;
          align-items: center;
          position: relative;
          margin-right: 0.1875rem;

          .mat-button-wrapper {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            justify-content: center;
          }
        }

        .filter-container.remove-filter {
          display: none;
        }

        .mat-icon {
          display: flex;
          justify-content: center;
          width: 0.75rem !important;

          svg path {
            fill: #333333 !important; // cor dos itens
          }
        }

        .colapse-all-icon {
          svg path {
            fill: #1B4693 !important;
          }
        }

        .filter-icon,
        .sort-icon,
        .pin-icon {
          opacity: 0; // Esconde os itens
        }

        .filter-container.activate,
        .sort-container.activate,
        .pin-container.activate {
          // Adiciona a borda no item ativo
          opacity: 1;
          color: #000000;
          justify-content: center;
        }

        .filter-icon.activate,
        .sort-icon.activate,
        .pin-icon.activate {
          // Atera a cor e o tamanho no item ativo
          opacity: 1;
          color: #000000 !important;
          fill: #000000 !important;
          transform: scale(1.1);
        }

        .filter-container:focus,
        .sort-container:focus,
        .pin-container:focus {
          // Adiciona a borda no item ativo
          width: 1.375rem;
          height: 1.375rem;
          border: 0.125rem solid #102956;
          border-radius: 0.3125rem;
          opacity: 1;
          color: #000000;
          justify-content: center;
        }

        &:hover {
          background-color: #EBEBEB;

          .filter-icon,
          .sort-icon,
          .pin-icon {
            // Atera a cor e o tamanho no item no hover
            opacity: 1;

            svg path {
              fill: #000000 !important; // cor dos itens
            }

            color: #000000 !important;
            transform: scale(1.3);
          }

          .filter-icon.activate,
          .sort-icon.activate,
          .pin-icon.activate {
            // Atera a cor e o tamanho no item ativo no hover
            opacity: 1;
            color: #000000 !important;
            fill: #000000 !important;
          }
        }

        .mat-form-field {
          width: 0;

          .mat-select-arrow {
            border-left: none;
            border-right: none;
            border-top: none;
            margin: unset;
          }
        }
      }

      [aria-sort="ascending"] {
        .mat-sort-header-button {
          &:before {
            opacity: 1;
            content: "\f15e" !important;
          }
        }

        &:hover {
          .mat-sort-header-button {
            &:before {
              opacity: 1;
              animation: unset;
              content: "\f15e" !important;
            }
          }
        }
      }

      [aria-sort="descending"] {
        .mat-sort-header-button {
          &:before {
            opacity: 1;
            content: "\f15d" !important;
          }
        }

        &:hover {
          .mat-sort-header-button {
            &:before {
              opacity: 1;
              animation: unset;
              content: "\f15d" !important;
            }
          }
        }
      }
    }

    tr.mat-header-row {
      height: 2.875rem;
    }
  }

  tbody {
    background-color: $background-box-color;
    box-shadow: 0 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.2),
      0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.0625rem 0.3125rem 0rem rgba(0, 0, 0, 0.12);

    tr {
      text-align: left;

      td {
        padding: 0 0.75rem;
        border-bottom: none;
        white-space: nowrap;

        .mat-icon.fa {
          font-size: 0.875rem;
          color: $primary;
        }

        .mat-icon-button {
          width: 1.875rem;
          height: 1.875rem;
          line-height: 1.875rem;
        }

        .mat-icon-button:disabled {
          .mat-icon.fa {
            color: unset;
          }
        }

        .mat-form-field.text {
          max-width: 100%;
          width: 8.125rem;
        }

        .mat-form-field.date {
          max-width: 10.625rem;
        }

        .mat-form-field {
          white-space: pre;
          max-width: 11.0625rem;

          .mat-form-field-wrapper {
            padding-bottom: 0.1875rem;

            .mat-form-field-underline {
              position: unset;
              transition: height 0.2s ease-in-out;
              height: 0rem;
            }
          }

          .mat-form-field-infix {
            width: 100%;
            text-align: left;
            display: inline-flex;
            border-top: 0;

            .mat-input-element {
              margin-top: 0rem;
              border: none;
            }
          }
        }

        .mat-form-field :hover {
          .mat-form-field-infix {
            .mat-input-element {
              padding-left: 0.375rem;
              background-color: #DCE7F9;
              border: 0.0625rem solid #1B4693 !important;
              border-radius: 0.25rem;
              outline: #1B4693 !important;
            }
          }
        }

        .mat-form-field {
          .mat-form-field-infix {
            .mat-input-element {
              // border: none;
              // border: 0.0625rem solid #1B4693 !important;
              // box-shadow: 0 0 0.5625rem #1B4693;
              // transition: border 0.3s ease-in-out;

              &:not(:focus) {
                border-color: gray;
                box-shadow: none;
              }

              &:focus {
                border: 0.125rem solid #1B4693 !important;
                border-radius: 0.25rem;
              }
            }
          }
        }

        .mat-form-field :active {
          .mat-form-field-infix {
            .mat-input-element {
              border: 0.125rem solid #1B4693 !important;
              border-radius: 0.25rem;
              outline: 0.125rem solid #1B4693 !important;
            }
          }
        }

        // &:hover {
        //   .mat-form-field-infix {
        //     .mat-input-element {
        //       border: 0.125rem solid #1B4693;
        //       border-radius: 0.25rem;
        //       outline: none;
        //     }
        //   }
        // }
      }

      td.mat-cell {
        @include text-inter-medium;
        color: $black;
      }
    }

    tr.mat-footer-row,
    tr.mat-row {
      height: 2.625rem;
      border-bottom: 0.0313rem solid #d1d1d175;
    }

    // tr:first-child {
    //   td:last-child {
    //     border-bottom: 0.0313rem solid #d1d1d175;
    //   }
    // }
    tr:last-child {
      td:last-child {
        border-bottom: 0.0313rem solid #d1d1d175;
      }
    }
  }
}
