@import './variables/colors.scss';
@import './mixins/mixins.scss';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

// -----------------------------------------------------------------------------------------------------
// @ Fonts
// -----------------------------------------------------------------------------------------------------

@font-face {
  font-family: $font-family-material;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/font/material-icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

@font-face {
  font-family: $font-family-material-outlined;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/font/material-icons/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}

.material-icons {
  font-family: $font-family-material;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: $font-family-material-outlined;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Inter Thin";
  font-style: thin;
  font-weight: 100;
  src: url("../assets/font/Inter/static/Inter-Thin-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Extra Light";
  font-style: extra-light;
  font-weight: 200;
  src: url("../assets/font/Inter/static/Inter-ExtraLight-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Light";
  font-style: light;
  font-weight: 300;
  src: url("../assets/font/Inter/static/Inter-Light-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Regular";
  font-style: regular;
  font-weight: 400;
  src: url("../assets/font/Inter/static/Inter-Regular-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Medium";
  font-style: medium;
  font-weight: 500;
  src: url("../assets/font/Inter/static/Inter-Medium-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Semi Bold";
  font-style: semi-bold;
  font-weight: 600;
  src: url("../assets/font/Inter/static/Inter-SemiBold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Bold";
  font-style: bold;
  font-weight: 700;
  src: url("../assets/font/Inter/static/Inter-Bold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Extra Bold";
  font-style: extra-bold;
  font-weight: 800;
  src: url("../assets/font/Inter/static/Inter-ExtraBold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Black";
  font-style: black;
  font-weight: 900;
  src: url("../assets/font/Inter/static/Inter-Black-slnt=0.ttf");
}


@font-face {
  font-family: "Manrope Regular";
  font-style: black;
  font-weight: 400;
  src: url("../assets/font/Manrope/static/Manrope-Regular.ttf");
}


$font-family-base: "Inter";

:root {
  @media (min-width: 1921px) {font-size: .83vw;}
  @media (max-width: 1820px) {font-size: 94.75%;}
  @media (max-width: 1720px) {font-size: 89.5%;}
  @media (max-width: 1620px) {font-size: 84.32%;}
  @media (max-width: 1520px) {font-size: 79.12%;}
  @media (max-width: 1420px) {font-size: 73.87%;}
  @media (max-width: 1320px) {font-size: 68.68%;}
  // @media (max-width: 1220px) {font-size: 39.71%;}
  // @media (max-width: 1120px) {font-size: 36.46%;}
  // @media (max-width: 1020px) {font-size: 33.20%;}
  // @media (max-width: 920px) {font-size: 29.95%;}
  // @media (max-width: 820px) {font-size: 26.70%;}
  //  @media (max-width: 720px) {font-size: 23.44%;}
  @media (max-width: 600px) {font-size: 100%;}
}

// -----------------------------------------------------------------------------------------------------
// @ Default
// -----------------------------------------------------------------------------------------------------

/* You can add global styles to this file, and also import other style files */
.ngx-material-timepicker-toggle svg {
  fill:#666666;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  border-radius: 1.25rem;
  background-color: #EBF5FF;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color-dark);
  border-radius: 0.25rem;
}
::-webkit-scrollbar-thumb:hover {
  background: $blue-400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: transparent;
}

/// CALENDAR PICKER CSS
.calendar-picker, .calendar-picker-month, .calendar-picker-year, .calendar-picker-period   {
  width: 21.875rem !important;

  .mat-calendar-content {
    padding: 0.5rem;
  }
  .mat-calendar-table-header {
    display: none;
  }

  .mat-calendar-body-label:not(:last-of-type) {
    visibility: hidden;
  }

  .mat-calendar-body-label:last-of-type {
      display: none;
  }

  ::-webkit-scrollbar {
    width: 0.125rem;
    height: 3.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: $blue-400;
  }

  svg {
    height: 75% !important;
  }
}

.calendar-picker, .calendar-picker-month, .calendar-picker-period {
  height: auto !important;
}

.calendar-picker, .calendar-picker-period {

  .mat-calendar-body-cell-content {
    color: $gray-400;
    @include text-font-inter;
    font-weight: 400;
    font-size: 0.9375rem;
    border-radius: 0.25rem;
    line-height: 1.25rem;
    height: 2.1875rem;
    width: 2.1875rem;
    flex-direction: column;

    border-width: 0;
    border-style: none;

    &:hover {
      background-color: $blue-100 !important;
      font-weight: 600 !important;
      color: $blue-400 !important;
    }
  }
  .mat-calendar-body-today {
    background-color: $white !important;
    font-weight: 600 !important;
    color: $blue-400 !important;
    padding-bottom: 0.5rem;

    &::before {
      content: 'Hoje';
      font-size: 0.5rem;
      line-height: 0.5rem;
      text-transform: uppercase;
    }
  }

  .mat-calendar-body-selected {
    background-color: $blue-100 !important;
    font-weight: 600 !important;
    color: $blue-400 !important;
  }
}

.calendar-picker-month, .calendar-picker-year {
  .mat-calendar-body-cell-content {
    color: $gray-500;
    @include text-font-inter;
    font-weight: 400;
    font-size: 0.9375rem;
    border-radius: 0.25rem;
    line-height: 1.25rem;
    height: 2.5rem;
    width: 5rem;
    background-color: $gray-100;
    border-width: 0;
    border-style: none;

    &:hover {
      background-color: $blue-100 !important;
      font-weight: 600 !important;
      color: $blue-400 !important;
    }
  }

  .mat-calendar-body-today {
    background-color: $gray-100 !important;
    font-weight: 400 !important;
    color: $gray-500 !important;
  }

  .mat-calendar-body-selected {
    background-color: $blue-400 !important;
    font-weight: 600 !important;
    color: $white !important;
  }
}

.calendar-picker-year {
  height: 11.75rem !important;

  .mat-calendar-content {
    height: 11.625rem !important;
    display: block;
    overflow: auto;
  }
}

.calendar-picker-period {
  .mat-calendar-body-selected {
    background-color: $blue-400 !important;
    font-weight: 600 !important;
    color: $blue-100 !important;
  }
  .mat-calendar-body-in-preview {
    color: $white !important;
  }
  .mat-calendar-body-preview-end {
    width: 80%;
  }

  .mat-calendar-body-in-range::before, .mat-calendar-body-in-preview {
    background-color: $blue-100 !important;
    height: 75% !important;
  }

  .mat-calendar-body-range-end::before,
  .mat-calendar-body-range-end::after {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 80% !important;
  }

  .mat-calendar-body-range-start::before,
  .mat-calendar-body-range-start::after {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Inter Regular", "Helvetica Neue", Arial, sans-serif !important;
}

/*
 * Overwriting CSS using styles.scss is only when it is not possible to change in local scope,
 * and you should avoid using CSS in global scope. If the component is shared, CSS should be
 * in scope and thus shared where to use it.
 */

// -----------------------------------------------------------------------------------------------------
// @ Global Classes
// -----------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------
// @ Animations
// -----------------------------------------------------------------------------------------------------

.spinner {
  -webkit-animation: rotating 2s linear 0;
  -moz-animation: rotating 2s linear 0;
  -ms-animation: rotating 2s linear 0;
  -o-animation: rotating 2s linear 0;
  animation: rotating 2s linear 0;

  &.run {
    animation-iteration-count: infinite;
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// MyToastr
.toast-top-right {
  top: 0.75rem;
  right: 0.75rem;
  position: absolute;
}
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";


.mat-input-element {
  overflow: hidden;
}

//Text-Field
.custom-outline-text-field .mat-form-field-flex {
  .mat-form-field-outline {
      color: transparent !important;
      border-width: 0rem !important;
      background-color: #EBEBEB;
      border-radius: 0.25rem;
    }
    input {
      height: 1.125rem;
      color: #595959;
    }
}

.custom-outline-text-field .mat-form-field-flex:hover {
  box-shadow: none !important;
  .mat-form-field-outline {
    box-shadow: none !important;
    }
    input{
      box-shadow: none !important;
      color: #595959;
    }
}

//Text-Input
.custom-outline-sucess .mat-form-field-flex {
  .mat-form-field-outline {
      color: var(--green-300) !important;
      border-width: 0.0625rem !important;
     }
     input {
      width: 85%;
     }
     textarea{
      width: 90%;
     }
}
.custom-outline-error .mat-form-field-flex {
  .mat-form-field-outline {
      color: var(--red-300) !important;
      border-width: 0.0625rem !important;
     }
     input{
      width: 85%;
     }
     textarea{
      width: 90%;
     }
}
.custom-outline-other .mat-form-field-flex {
  .mat-form-field-outline {
    color: var(--gray-300) !important;
    border-width: 0.0625rem !important;
   }
     input{
      width: 85%;
     }
     textarea{
      width: 90%;
     }
}

//Time-Input
.custom-outline-time-input-sucess .mat-form-field-flex {
  .mat-form-field-outline {
      color: var(--green-300) !important;
      border-width: 0.0625rem !important;
     }
}
.custom-outline-time-input-error .mat-form-field-flex {
  .mat-form-field-outline {
      color: var(--red-300) !important;
      border-width: 0.0625rem !important;
     }
}

//DataPicker-Input
.custom-outline-datapicker-sucess .mat-form-field-flex {
  .mat-form-field-outline {
      color: var(--green-300) !important;
      border-width: 0.0625rem !important;
     }
     input {
      width: 80%;
     }
}
.custom-outline-datapicker-error .mat-form-field-flex {
  .mat-form-field-outline {
      color: var(--red-300) !important;
      border-width: 0.0625rem !important;
     }
     input{
      width: 80%;
     }
}
.custom-outline-datapicker-other .mat-form-field-flex {
  input{
   width: 80%;
  }
}

//Loading
.load-size .loading{
  width: 1.4375rem !important;
  height: 1.4375rem !important;
}

.warning-icon{
  svg{
    width: 1rem;
  }
}

//Accordion
.accordion{

 .mat-expansion-indicator{
  // background-color: yellow;
  color: var(--primary-color-medium);
 }
 .mat-expansion-indicator::after {
  color: var(--primary-color-medium);
 }

  .mat-expansion-panel{
    border: 0.0625rem solid var(--gray-300);
    box-shadow: 0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0) !important;
    margin: 0.3125rem 0rem;

    .mat-expansion-panel-header {
      min-height: 3.3125rem;
      border-radius: 0rem;
      background-color: var(--white);
      //hover-focus mat-expansion-panel-header
      &:hover{
        background-color: var(--primary-color-light) !important;
      }
      &:focus {
        border: 0.125rem solid var(--primary-color-dark) !important;
      }
    }
    //hover-focus mat-expansion-panel
    &:hover{
      box-shadow: 0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.5) !important;
    }
    &:focus{
      box-shadow: 0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.5) !important;
    }
  }

  .mat-expansion-panel-header-title {
    @include text-font-inter;
    font-size: 0.875rem;
    color: var(--primary-color-medium);
  }
  .mat-expansion-panel-body {
    padding: 0 0 1rem !important;
  }

  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused {
    background-color: var(--white);
  }

}

.icon-info {
  svg{
    width: 1.25rem !important;
  }
 }

 .button-analytics{
  svg {
    width: 1.25rem;
    path {
    fill: var(--primary-color-medium);
    }
  }
 }

 .editTitle .mat-icon {
  svg{
    width: 1.25rem;
    path{
      fill: var(--primary-color-medium);
    }
  }
}

 .button-analytics-blue{
  padding: 0.3438rem;
  background-color: var(--primary-color-medium) !important;
  border-radius: 0.25rem;
  svg path {
    fill: var(--white);
  }
 }

@media only screen and (max-width: 599px) {
  .mat-drawer-content {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  .none-mobile {display: none !important;}

  .cdk-global-overlay-wrapper {
    align-items: flex-end !important;
    justify-content: stretch !important;
    .cdk-overlay-pane {
      max-width: 100vw !important;
      width: 100vw !important;
      animation: openDialogMobile .3s forwards;
    }
  }

  .mat-dialog-container {
    transform: none !important;
  }
}
@keyframes openDialogMobile {
  0% {
    transform: translateY(6.25rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 40%);
}

.container-buttons-topnavbar {
  z-index: 1;
  &.top-navbar-tablet {display: none;}
  &, .list-labels-topnavbar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .label-switch {
    font-size: 0.875rem !important;
    line-height: 1.25rem;
  }
  .icon-check-topnavbar {
    margin: -0.125rem 0.5rem 0 0;
    .mat-icon {
      font-size: 1rem !important;
      height: 1rem !important;
      width: 1rem !important;
      line-height: 1rem !important;
    }
  }
  .list-labels-topnavbar  {
    padding-left: 0.5rem;
    > li:not(:last-child) {
      padding-right: 0.5rem;
      border-right: 0.0625rem solid var(--gray-300);
    }
  }
  .label-topnavbar {
    @include text-inter-medium;
    margin-bottom: 0;
    > span {
      color: var(--primary-color-medium);
    }
  }
}

.mat-dialog-container {
  padding: 1rem !important;
}

.modal-container {
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;

  > header > div, > footer > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  > header {
    &, > div {margin-bottom: 1rem;}
    > div {
      justify-content: space-between;
      > h4, h5 {
        display: flex;
        gap: 0.5rem;
        color: $gray-500;
        margin: 0;
        padding: 0;
        .icon-modal {
          font-size: 1.25rem !important;
          line-height: 1.2 !important;
          width: 1.25rem !important;
          height: 1.25rem !important;
          &.blue-icon {
            path {
              fill: $blue-300 !important;
            }
          }
          &.green-icon {
            path {
              fill: $green-300 !important;
            }
          }
          path {fill: $yellow-400 !important;}
        }
      }
      > h4 {
        @include text-inter-h4-semibold;
        .icon-modal {
          margin-top: 0.125rem;
        }
      }
      > h5 {
        @include text-inter-large;
        .icon-modal {
          margin-top: -0.0625rem;
        }
      }

      > button {
        background-color: transparent;
        border: none;
        .material-icons {
          transition: color .3s ease;
          width: 1rem;
          height: 1rem;
          color: $gray-400;
        }
        &:hover {
          color: $gray-500;
        }
      }
    }
  }

  > footer {
    > div {
      justify-content: flex-end;
    }
    &.divider, > div {margin-top: 1rem;}
  }
}

@media screen and (max-width: 700px) {
  .modal-container {
    > header {
      &, > div {margin-bottom: 0.875rem;}
      > div {
        > h5 {
          font-size: 1rem;
          line-height: 1.5rem;
          .icon-modal {
            font-size: 1.125rem !important;
            width: 1.125rem !important;
            height: 1.125rem !important;
          }
        }
      }
    }
    > footer {
      &.divider, > div {margin-top: 0.875rem;}
    }
  }
}

@media only screen and (max-width: 599px) {
  .modal-container {
    > header {
      > div {
        &:not(.contains-close-button) {
          justify-content: center;
        }
      }
    }
    > footer {
      > div {
        align-items: stretch;
        gap: 1rem;
        flex-direction: column-reverse;

        button-induxtry {
          width: 100% !important;
          .buttons {
            display: block !important;
            &, .button-generic{
              width: 100% !important;
            }
            .button-generic {
              justify-content: center !important;
              height: 2.5rem !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .container-buttons-topnavbar {
    &.top-navbar-desktop {
      display: none !important;
    }
    &.top-navbar-tablet {
      display: flex;
    }
  }
}


@media screen and (max-width: 850px) {
  .container-buttons {
    .list-labels-topnavbar {display: none;}
  }
}

@media screen and (max-width: 599px) {
  .container-buttons {
    align-items: start;
    grid-template-columns: 2.5rem 1fr repeat(2, auto) !important;
    .tags-topnavbar {
      grid-column: span 4;
      grid-row: 2;
    }
  }
}

.container-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.container-flex-view-elements {
  display: flex;
  gap: 0.1875rem;
  flex-direction: column;
}

.js-plotly-plot .plotly [data-title]::after {
  right: calc(50% + 0.3125rem) !important;
}

.js-plotly-plot .plotly .modebar-btn {
  svg {
    &, path {
      transition: all .2s;
      fill: var(--primary-color-medium) !important;
    }
  }
  &:hover {
    svg {
      &, path {
        fill: var(--primary-color-dark) !important;
      }
    }
  }
}

.btn-download-graphics {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
