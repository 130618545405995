// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../variables/colors';
@import '../../variables/variables';
@import '../../mixins/mixins.scss';

// -----------------------------------------------------------------------------------------------------
// @ Mat Buttons
// -----------------------------------------------------------------------------------------------------

button.mat-raised-button,
button.mat-stroked-button,
button.mat-button {
  font-family: "Inter Semi Bold", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  align-items: center;
  padding: 0 0.5rem !important;
  text-align: center;
  text-transform: uppercase;
  min-width: 1.875rem !important;
  height: 2rem;
}

// Basic Button
button.mat-button {
  color: $primary-color-dark !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
}

button.mat-button[disabled] {
  color: $gray-200 !important;
  border: none;
}

// Raised Button
button.mat-raised-button {
  color: var(--primary-color-dark) !important;
  background-color: $primary-color-light !important;
  border: none;
}

button.mat-raised-button[disabled] {
  background-color: $gray-100 !important;
  color: $gray-200 !important;
  border: none;
}

// Stroked Button
button.mat-stroked-button {
  color: $white !important;
  background-color: var(--primary-color-dark) !important;
  box-shadow: 0rem 0.0313rem 0.0938rem $gray-300 !important;
  border: none;

  mat-icon {
    vertical-align: baseline !important;

    svg {
      fill: $white !important;
    }
  }
}

button.mat-stroked-button[disabled] {
  background-color: $white !important;
  color: $gray-200 !important;
  border: 0.125rem solid $gray-100 !important;
}

// Icon Button
button.mat-icon-button {
  mat-icon {
    vertical-align: baseline !important;
    svg path {
      fill: #1B4693 !important;
    }
  }
}

// Icone do Button Raised
button.mat-button,
button.mat-raised-button {
  mat-icon {
    vertical-align: baseline !important;
    svg path {
      fill: var(--primary-color-dark) !important;
    }
  }
}


button.mat-button[disabled],
button.mat-raised-button[disabled],
button.mat-stroked-button[disabled] {
  mat-icon {
    svg {
      fill: $gray-200 !important;
    }
  }
}
