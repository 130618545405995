// -----------------------------------------------------------------------------------------------------
// @ Colors
// -----------------------------------------------------------------------------------------------------

:root {
    //default colort
    --white: #ffffff;
    --black: #000000;

    //gray
    --gray-800: #6D6F72;
    --gray-650: #6D6D6D;
    --gray-500: #333333;
    --gray-400: #595959;
    --gray-300: #949494;
    --gray-200: #cccccc;
    --gray-100: #EBEBEB;

    // green
    --green-500: #0B4124;
    --green-400: #115F22;
    --green-350: #52E092;
    // --green-300: #A8F0C9;
    --green-300: #1CA058;
    --green-200: #7DE8AD;
    --green-100: #DCF9E9;

    // blue
    --blue-600: #F5F8FB;
    --blue-500: #102956;
    --blue-400: #1F52AB;
    --blue-350: #0074C0;
    --blue-300: #5286E0;
    --blue-200: #A9C2EF;
    --blue-100: #E0E9F6;

    // red
    --red-500: #660700;
    --red-400: #A80B00;
    --red-300: #ff2e1f;
    --red-200: #ffa099;
    --red-100: #FFD9D6;

    // yellow
    --yellow-500: #5F4407;
    --yellow-400: #BD870F;
    --yellow-300: #F0BA42;
    --yellow-200: #F8DDA0;
    --yellow-100: #FDF1D8;

    // purple
    --purple-500: #250a5c;
    --purple-400: #4a13b9;
    --purple-300: #7d46ec;
    --purple-200: #BEA2F6;
    --purple-100: #E5DAFB;

    // blue2
    --blue2-500: #0a535c;
    --blue2-400: #13a5b9;
    --blue2-350: #0074C0;
    --blue2-300: #46d8ec;
    --blue2-200: #a3ecf6;
    --blue2-100: #edfbfd;

    // orange
    --orange-500: #5c220a;
    --orange-400: #b94513;
    --orange-300: #ec7846;
    --orange-200: #f6bba3;
    --orange-100: #fdf1ed;

    // pink
    --pink-500: #5c0a31;
    --pink-400: #b91363;
    --pink-300: #ec4696;
    --pink-200: #f6a3ca;
    --pink-100: #fdedf4;

    // brown
    --brown: #946300;

    // -----------------------------------------------------------------------------------------------------
    // @ Themes Colors
    // -----------------------------------------------------------------------------------------------------

    // primary colors
    --primary-color-light: #DCE7F9;
    --primary-color-medium: #1B4693;
    --primary-color-dark: #102956;

    // secondary colors
    --secondary-color-light: #C3B1E7;
    --secondary-color-medium: #693CC1;
    --secondary-color-dark: #2A184E;

    // success colors
    --success-color-light: --green-100;
    --success-color-medium: --green-400;
    --success-color-dark: --green-500;

    // error colors
    --error-color-light: --red-100;
    --error-color-medium: --red-400;
    --error-color-dark: --red-500;

    // -----------------------------------------------------------------------------------------------------
    // @ Default Colors
    // -----------------------------------------------------------------------------------------------------
    --background-box-color: --white;

}






// default colors
$white: #ffffff !default;
$black: #000000 !default;

// gray
$gray-800: #6D6F72 !default;
$gray-650: #6D6D6D !default;
$gray-500: #333333 !default;
$gray-400: #666666 !default;
$gray-300: #949494 !default;
$gray-200: #cccccc !default;
$gray-100: #EBEBEB !default;

// green
$green-500: #0F5730 !default;
$green-400: #18874A !default;
$green-350: #52E092 !default;
// $green-300: #A8F0C9 !default;
$green-300: #1CA058 !default;
$green-200: #a5f3c8 !default;
$green-100: #EEFCF4 !default;

// blue
$blue-600: #102956 !default;
$blue-550: #1B4693 !default;
$blue-500: #004a8f !default;
$blue-400: #006acc !default;
$blue-350: #0074C0 !default;
$blue-300: #339dff !default;
$blue-200: #99ceff !default;
$blue-100: #ebf5ff !default;

// red
$red-500: #660700 !default;
$red-400: #cc0e00 !default;
$red-300: #ff2e1f !default;
$red-200: #ffa099 !default;
$red-100: #ffeceb !default;

// yellow
$yellow-500: #664a00 !default;
$yellow-400: #cc9400 !default;
$yellow-300: #ffcd48 !default;
$yellow-200: #ffe399 !default;
$yellow-100: #fff9eb !default;

// purple
$purple-500: #250a5c !default;
$purple-400: #4a13b9 !default;
$purple-300: #7d46ec !default;
$purple-200: #bea3f6 !default;
$purple-100: #f2edfd !default;

// blue2
$blue2-500: #0a535c !default;
$blue2-400: #13a5b9 !default;
$blue2-350: #0074C0 !default;
$blue2-300: #46d8ec !default;
$blue2-200: #a3ecf6 !default;
$blue2-100: #edfbfd !default;

// orange
$orange-500: #5c220a !default;
$orange-400: #b94513 !default;
$orange-300: #ec7846 !default;
$orange-200: #f6bba3 !default;
$orange-100: #fdf1ed !default;

// pink
$pink-500: #5c0a31 !default;
$pink-400: #b91363 !default;
$pink-300: #ec4696 !default;
$pink-200: #f6a3ca !default;
$pink-100: #fdedf4 !default;

// brown
$brown: #946300;

// -----------------------------------------------------------------------------------------------------
// @ Themes Colors
// -----------------------------------------------------------------------------------------------------

// primary colors
$primary-color-light: #DCE7F9;
$primary-color-medium: #1B4693;
$primary-color-dark: #102956;

// secondary colors
$secondary-color-light: #C3B1E7;
$secondary-color-medium: #693CC1;
$secondary-color-dark: #2A184E;

// success colors
$success-color-light: $green-100;
$success-color-medium: $green-400;
$success-color-dark: $green-500;

// error colors
$error-color-light: $red-100;
$error-color-medium: $red-400;
$error-color-dark: $red-500;

// -----------------------------------------------------------------------------------------------------
// @ Default Colors
// -----------------------------------------------------------------------------------------------------
$background-box-color: $white;
